import React from 'react'

export const ForwardArrow = () => {
  return (
    <svg width="12" height="18" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "4px", marginLeft: "4px" }}>
          <path d="M1 13L7 7L1 1" stroke={'black'} strokeWidth={'2'} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}

