// NotFound.tsx
import React from 'react';
import styled from 'styled-components';

const NotFound: React.FC = () => {
    return (
        <NotFoundContainer>
            <Title>404 - Page Not Found</Title>
            <Message>Sorry, the page you are looking for does not exist.</Message>
        </NotFoundContainer>
    );
};

export default NotFound;

const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f8f8;
    text-align: center;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 1.5rem;
    color: #666;
`;
