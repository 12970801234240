import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { TextH3 } from '../lib';
import { TabBox } from './style';
import GameSlider from './GamesSlider';

const DeveloperTabs =  () => (
    <TabBox style={{padding:"32px 0"}}>
        <TextH3 style={{  fontSize: '24px',paddingBottom:"16px" }}>Top charts</TextH3>
        <Tabs>
            <TabList>
                <Tab>Top games</Tab>
            </TabList>
            <TabPanel>
               <GameSlider/>
            </TabPanel>
        </Tabs>
</TabBox>
);
export default DeveloperTabs
