import styled from "styled-components";

export const Mainheading = styled.h2`
 color:#fff;
 font-size:52px;
 margin:0;
 padding-bottom:22px;
 font-weight:700;
 @media (max-width: 600px) {
 font-size:32px;
  }
`;