import styled from "styled-components";

export const TabBox = styled.div`
padding:32px 0;
.react-tabs {
    .react-tabs__tab-list {
      border-bottom:none;
    };
    .react-tabs__tab {
    padding: 6px 24px 8px 24px;
    border: 1px rgb(218, 220, 224) solid;
    font-size: 16px;
    font-weight: 500;
    margin-right:16px;
    border-radius: 50px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
};

.react-tabs__tab--selected {
    background-color: #e6f3ef;
    border:none;
    color: #056449;
    background: #e6f3ef;  
};

.react-tabs__tab--disabled {
    color: gray;
    cursor: not-allowed;
};
};
`
export const StyledSlider = styled.div`
    .slick-list {       
             @media (max-width: 600px) {
              .slick-slide {
              display:flex;
              justify-content:center;
              }
              };
    }
`;

export const MainVideo = styled.video`
    object-fit: cover;
    width: 400px;
    height: 250px;
    border-radius: 8px;
    @media (max-width: 600px) {
     width: 300px;
     };
`;
export const VideoNotFound = styled.div`
    width: 400px;
    height: 255px;
    border-radius: 8px;
    display:flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 600px) {
     width: 300px;
    };
`;
export const ArrowButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    height: 50px;
    width: 50px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    outline: none;
    @media (max-width: 600px) {    
        height: 30px;
        width: 30px;
    }

`;

export const BackButton = styled(ArrowButton)`
    left: -25px;
    @media (max-width: 600px) {
        left: -4px;
    }
`;

export const ForwardButton = styled(ArrowButton)`
    right: 0;
`;

export const StyledIcon = styled.i`
    font-size: 14px;
`;
export const StyledLogo = styled.img`
  height: 90px;
  border-radius: 16px;
`;
export const LoadingBox = styled.div`
height:300px;
width:100%;
display:flex;
justify-content:center;
align-items:center;
`
 export const StyledBackground = styled.div`
  background: black;
`;

 export const MainContainer = styled.div`
  background-color: #212123;
  overflow: hidden;
`;

 export const MainInner = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

 export const MainVideoBlock = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(2.55682 * min(100vw, 440px));
  height: calc(1.43636 * min(100vw, 440px));
`;

 export const MainVideos = styled.video`
  object-fit: cover;
  position: absolute;
  right: 0;
  width: calc(2.55682 * min(100vw, 440px));
  height: calc(1.43636 * min(100vw, 440px));
  @media (max-width: 600px) {
    width: 600px;
  }
`;

 export const VideoCover = styled.div`
  overflow: hidden;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(90deg, #212123 0, transparent 56%),
    linear-gradient(0deg, #212123 0, transparent 56%),
    linear-gradient(270deg, #212123 0, transparent 56%);
`;

 export const MainInfo = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 19% 12px 5% 12px;
  background: linear-gradient(90deg, #212123 0, transparent 56%),
    linear-gradient(0deg, #212123 0, transparent 56%),
    linear-gradient(270deg, #212123 0, transparent 56%);
  width: 100%;
  @media (max-width: 600px) {
    align-items: center;
  }
`;

 export const StyledLink = styled.p`
  color: #6df378;
  margin-top: 0;
  margin-bottom: 4px;
  text-decoration: none;
  cursor: pointer;
`;

 export const StyledParagraph = styled.p`
  color: #c8c8c8;
  margin-top: 0;
`;

 export const StyledBcWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  gap: 50px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

 export const StyledLogos = styled.img`
  height: 70px;
  border-radius: 16px;
`;

 export const StyledH5 = styled.h5`
  display: flex;
  align-items: center;
`;

 export const StyledIcons = styled.i`
  font-size: 16px;
  padding-left: 4px;
`;

 export const StyledBtnWrapper = styled.div`
  width: 100%;
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

 export const StyledButton = styled.button`
  padding: 16px 100px;
  background-color: #6df378;
  font-size: 18px;
  font-weight: 700;
  color: #202124;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  outline: none;
  @media (max-width: 600px) {
    padding: 16px 60px;
  }
`;

 export const StyledButtonTrailer = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.54);
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  outline: 0;
  padding: 14px 21px;
  text-align: center;
  text-decoration: none;
  @media (max-width: 600px) {
    margin-top: 24px;
  }
`;

 export const StyledShareOption = styled.div`
  color: #6df378;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

 export const StyledSvg = styled.svg`
  width: 24px;
  height: 24px;
`;

 export const StyledP = styled.p`
  padding-left: 8px;
  color: #6df378;
`;

 export const Tooltip = styled.span`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 4px;
  top: -30px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
`;
 export const DownloadStats = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  z-index: 1000;
  text-align:center;
`;


 export const ProgressBarWrapper = styled.div`
  width: 100%;
   height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 16px;
  overflow: hidden;
`;

 export const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #6df378;
  transition: width 0.4s ease-in-out;
`;