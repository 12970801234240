import React from 'react';
import MainSlider from '../components/MainSlider';
import MainFooter from '../components/MainFooter';
import { User } from '../utlis';
interface Iprops {
  userData: User[]
  isLoading?: boolean
}

const Home: React.FC<Iprops> = ({ userData, isLoading }) => {


  return (
    <React.Fragment>
      <MainSlider userData={userData} isLoading={isLoading} />
      <MainFooter />
    </React.Fragment>
  );
};

export default Home;
