import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { TabBox } from './style';
import { User } from '../utlis';
import { Container } from '../lib';
import MainWindowSlider from './MainWindowSlider';
import MainPhoneSlider from './MainPhoneSlider';

interface Iprops {
    userData: User[]
    isLoading?: boolean
}

const MainSlider: React.FC<Iprops> = ({ userData, isLoading }) => {
   
    return (
        <Container style={{ paddingTop: '12px' }}>
            <TabBox style={{ padding: "32px 0" }}>
                <Tabs>
                    <TabList>
                        <Tab>Phone</Tab>
                        <Tab>Window</Tab>  
                    </TabList>  
                    <TabPanel>
                        <MainPhoneSlider userData={userData} isLoading={isLoading}  />
                    </TabPanel>
                    <TabPanel>
                        <MainWindowSlider userData={userData} isLoading={isLoading} deviceType />
                    </TabPanel>
                </Tabs>
            </TabBox>
        </Container>
    );
};

export default MainSlider;

