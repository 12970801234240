import React from 'react';
import { BackArrow, Box, Container, ForwardArrow, Text } from '../lib';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { User, truncateString, useFeaturedGameData, useScreenWidth } from '../utlis';
import ReactLoading from 'react-loading';
import DeveloperTabs from './DeveloperTabs';
import { BackButton, ForwardButton, LoadingBox, StyledSlider } from './style';
import styled from 'styled-components';
import MainWindowSlider from './MainWindowSlider';

interface IProps {
    userData: User[];
    isLoading?: boolean;
}

const ArrowLeft = ({ currentSlide, slideCount, ...props }: any) => {
    const { onClick } = props;
    const isVisible = currentSlide !== 0;

    return (
        <BackButton id="back-btn" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
            <BackArrow />
        </BackButton>
    );
};

const ArrowRight = ({ currentSlide, slideCount, ...props }: any) => {
    const { onClick } = props;
    const screenWidth = useScreenWidth();
    const isVisible = screenWidth < 600
        ? currentSlide !== slideCount - 1 && slideCount > 1
        : currentSlide !== slideCount - 2 && slideCount > 2;

    return (
        <ForwardButton id="forward-btn" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
            <ForwardArrow />
        </ForwardButton>
    );
};

const MainPhoneSlider: React.FC<IProps> = ({ userData, isLoading }) => {
    const navigate = useNavigate();
    const screenWidth = useScreenWidth();
    const { data: gameData } = useFeaturedGameData();

    const handleInstall = (apk: string) => {
        if (!!apk) {
            const apkUrl = apk;
            const downloadLink = document.createElement('a');
            downloadLink.href = apkUrl;
            downloadLink.download = '';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };



    const MYSliderSetting = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.4,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    vertical: true,
                },
            },
        ],
    };
    const displayedGames = gameData && gameData.filter((item: any) => item.attributes.featured_media !== null);
    return (
        <>  <Container style={{ paddingTop: '12px' }}>
            {isLoading ?
                <LoadingBox>
                    <ReactLoading type='spinningBubbles' color='black' height={120} width={75} />
                </LoadingBox>
                :
                <StyledSlider>
                    {displayedGames && <Slider {...MYSliderSetting}>
                        {displayedGames.map((slide: any, index: number) => {
                            return (
                                <PhoneSliderBox key={index}>
                                    <MainImageBox src={slide.attributes.featured_media?.data?.[0]?.attributes?.url || ''} />
                                    <DetailsBox>
                                        <StyledLogo src={slide.attributes.icon.data?.attributes?.url} alt={slide.attributes.icon.data?.attributes?.url} onClick={() => navigate(`/viewgame?id=${slide.id}`)} />
                                        <ResponsiveBox>
                                            <Box>
                                                <Text style={{ color: '#fff', fontWeight: '500', width: "120px" }} onClick={() => navigate(`/viewgame?id=${slide.id}`)}>
                                                    {screenWidth > 600 ? truncateString(slide.attributes.name, 22) : truncateString(slide.attributes.name, 15)}
                                                </Text>
                                                <ContentBox>
                                                    <Text style={{ color: '#fff', cursor: 'pointer' }}>
                                                        {slide.attributes.category}
                                                    </Text>
                                                </ContentBox>
                                            </Box>
                                            <Box>
                                                <InstallButton onClick={() => handleInstall(slide.attributes.apk.data?.attributes.url)}>Install</InstallButton>
                                                <Text style={{
                                                    color: '#fff', fontSize: '12px', width: '100px'
                                                }}>In-app purchases</Text>
                                            </Box>
                                        </ResponsiveBox>
                                    </DetailsBox>

                                </PhoneSliderBox>
                            )
                        })}
                    </Slider>}
                </StyledSlider>}
            <DeveloperTabs />

        </Container>
            <MainWindowSlider userData={userData} isLoading={isLoading} />
        </>
    );
};

export default MainPhoneSlider;

const PhoneSliderBox = styled.div`
    position: relative;
`;

const DetailsBox = styled.div`
    display:flex;
    align-items:center;
    width: 88.5%;
    bottom: 1.1%;
    padding-left: 5%;
    padding: 20px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    zIndex:20;
     background-color: rgba(0, 0, 0, 0.5);
     color: white; 
     gap:12px;
`;

const MainImageBox = styled.img`
    object-fit: fill;
    width: 500px;
    height: 280px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    @media (max-width: 600px) {
        width: 100%;
            height: 300px;

    }
`;

const StyledLogo = styled.img`
    height: 70px;
    width:70px;
    border-radius: 16px;
`;

const InstallButton = styled.button`
    height: 32px;
    min-height: 32px;
    min-width: 86px;
    color: #000000;
    background-color: #6df378;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
`;

const ContentBox = styled.div`
    width: 280px;
    @media (max-width: 600px) {
        width: 100px;
    }
`;

const ResponsiveBox = styled.div`
 display: flex;
 justify-content: space-between; 
 align-items: center;
 `