import React from 'react';
import Slider from 'react-slick';
import ReactLoading from 'react-loading';
import { BackButton, ForwardButton, StyledIcon, StyledSlider } from './style';
import { BackArrow, Box, ForwardArrow, Text, TextH3 } from '../lib';
import { useAllGameData, useScreenWidth } from '../utlis';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ArrowLeft = ({ currentSlide, slideCount, ...props }: any) => {
    const { onClick } = props;
    const isVisible = currentSlide !== 0;

    return (
        <BackButton id="back-btn" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
            <BackArrow />
        </BackButton>
    );
};

const ArrowRight = ({ currentSlide, slideCount, ...props }: any) => {
    const { onClick } = props;
    const screenWidth = useScreenWidth();
    const isVisible = screenWidth < 600
        ? currentSlide !== slideCount - 1 && slideCount > 1
        : currentSlide !== slideCount - 3 && slideCount > 3;
    return (
        <ForwardButton id="forward-btn" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
            <ForwardArrow />
        </ForwardButton>
    );
};

const GamesSlider = () => {
    const { data: gameData } = useAllGameData();
    const navigate = useNavigate();

    if (!gameData || gameData.length === 0) {
        return <Box style={{ height: "40vh", display: "flex", alignItems: "end", justifyContent: "center" }}>
            <ReactLoading type='spinningBubbles' color='black' height={120} width={75} />
        </Box>;
    }

    const chunkedData = chunkArray(gameData.slice(0,15), 3);

    const MYSliderSetting = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    vertical: true,
                },
            },
        ],
    };

    return (
        <StyledSlider>
            <Slider {...MYSliderSetting}>
                {chunkedData.map((chunk, chunkIndex) => (
                    <MoreGamesWrapper key={chunkIndex}>
                        {chunk.map((item: any, itemIndex: number) => (
                            <GameItem key={chunkIndex * 3 + itemIndex} onClick={() => navigate(`/viewgame?id=${item && item.id}`)}>
                                {chunkIndex * 3 + itemIndex + 1}
                                <GameImage src={item && item.attributes && item.attributes.icon && item.attributes.icon.data && item.attributes.icon.data.attributes ? item.attributes.icon.data.attributes.url : ''} />
                                <Box>
                                    <StyledTextH3>{item && item.attributes ? item.attributes.name : ''}</StyledTextH3>
                                    <Text style={{ color: '#5F6368', fontSize: '16px' }}>{item && item.attributes ? item.attributes.category : ''}</Text>
                                    <Text style={{ color: '#5F6368', fontSize: '16px' }}>
                                        {item && item.attributes ? item.attributes.rating : ''} <StyledIcon className="material-icons">star</StyledIcon>
                                    </Text>
                               </Box>
                            </GameItem>
                        ))}
                    </MoreGamesWrapper>
                ))}
            </Slider>
        </StyledSlider>
    );
};

export default GamesSlider;

const MoreGamesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns per slide */
    grid-gap: 20px;
    padding-top: 32px;
`;

const GameItem = styled.div`
    display: flex;
    align-items: center;
    gap:12px;
    cursor: pointer;
    margin-bottom:24px;
`;

const GameImage = styled.img`
    width: 75px;
    height: 75px;
    border-radius: 20px;
`;

const StyledTextH3 = styled(TextH3)`
    font-weight: 400;
    font-size: 18px;
    margin-top: 8px;
`;

// Helper function to chunk the array
function chunkArray(array: any[], size: number) {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
}
