export const formatNumber = (num: number) => {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(0) + "B";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(0) + "M";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(0) + "K";
  }
  return num.toString();
};
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  // Extract date components
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
export const truncateString = (str: string ,length:number) => {
  if (str.length <= length) {
    return str;
  } else {
    return str.substring(0, length) + "...";
  }
};
