import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TonGames from './views/TonGames';
import Home from './views/Home';
import ViewGame from './views/ViewGame';
import { useUserData } from './utlis';
import NotFound from './views/NotFound';
import Navbar from './components/Navbar';

function App() {
  const { data: userData, isLoading } = useUserData();
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home userData={userData} isLoading={isLoading} />} />
        <Route path="/tongames" element={<TonGames />} />
        <Route path="/viewgame" element={<ViewGame />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
