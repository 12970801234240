import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '../lib';
import { Game,  useGameData, User, useUserData } from '../utlis';
import ReactLoading from 'react-loading';
import ScrollToTop from '../components/ScrollToTop';

const LazyMain = React.lazy(() => import('../components/Main'));
const LazySlider = React.lazy(() => import('../components/Slider'));
const LazyContentSection = React.lazy(() => import('../components/ContentSection'));
const LazyFooterSection = React.lazy(() => import('../components/FooterSection'));

const ViewGame = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const gameIdFromSearch = params.get('id');
    const { data: gameData } = useGameData(gameIdFromSearch);
    const { data: userData } = useUserData();

    // Check if gameData and userData are available and not null
    if (!gameData || !gameData.attributes || !userData) {
        return (
            <Box style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ReactLoading type='spinningBubbles' color='black' height={120} width={75} />
            </Box>
        );
    }

    const filteredGames = userData.filter((item: any) =>
        item.username === gameData.attributes.users_permissions_user?.data?.attributes?.username
    ).map((slide: User) => {
        const featuredGame = slide.games.find((game: Game) => game.is_featured);
        const defaultGame = slide.games[0];
        return {
            username: slide.username,
            icon: slide.icon,
            all_games: slide.games,
            solgan: slide.slogan,
            game: featuredGame || defaultGame
        };
    });

    const res = filteredGames.map((item: any) => item);

    return (
        <Box>
            <ScrollToTop/>
            <Suspense fallback={<Box style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ReactLoading type='spinningBubbles' color='black' height={120} width={75} />
            </Box>}>
                <LazyMain
                    gameData={gameData}
                    slide={res && res[0]}
                />
                <LazySlider screen_shot={gameData.attributes.screenshots} />
                <LazyContentSection about={gameData.attributes.description} updated_on={gameData.attributes.publishedAt} />
                <LazyFooterSection />
            </Suspense>
        </Box>
    );
};

export default ViewGame;
