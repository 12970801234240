import React from 'react';
import { BackArrow, Box, Container, ForwardArrow, Text, TextH3 } from '../lib';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { Game, Icon, User, truncateString, useScreenWidth } from '../utlis';
import ReactLoading from 'react-loading';
import { BackButton, ForwardButton, LoadingBox, MainVideo, StyledIcon, StyledLogo, StyledSlider, VideoNotFound } from './style';

interface Iprops {
  userData: User[]
  isLoading?: boolean
  deviceType?:boolean
}
const ArrowLeft = ({ currentSlide, slideCount, ...props }: any) => {
  const { onClick } = props;
  const isVisible = currentSlide !== 0;

  return (
    <BackButton id="back-btn" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
      <BackArrow />
    </BackButton>
  );
};

const ArrowRight = ({ currentSlide, slideCount, ...props }: any) => {
  const { onClick } = props;
  const screenWidth = useScreenWidth();
  const isVisible = screenWidth < 600
    ? currentSlide !== slideCount - 1 && slideCount > 1
    : currentSlide !== slideCount - 3 && slideCount > 3;
  return (
    <ForwardButton id="forward-btn" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
      <ForwardArrow />
    </ForwardButton>
  );
};
const MainWindowSlider: React.FC<Iprops> = ({ userData, isLoading, deviceType }) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const filteredGames = userData && userData?.map((slide: User) => {
    const featuredGame = slide.games.find((game: Game) => game.is_featured);
    const defaultGame = slide.games[0];
    const res: {
      username: string;
      icon: Icon;
      all_games: Game[];
      solgan: string;
      game: Game;
    } = {
      username: slide.username,
      icon: slide.icon,
      all_games: slide.games,
      solgan: slide.slogan,
      game: featuredGame || defaultGame
    };
    return res;
  });
  const displayedGames = filteredGames && deviceType ? filteredGames.filter((item) => item.game.device_type === 'Windows'): filteredGames;

  const MYSliderSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          vertical: true,
        },
      },
    ],
  };
  return (
    <Container style={{ paddingTop: '12px' }}>
      <Box style={{ margin: '15px 0' }}>
        {!deviceType ?
          <TextH3 style={{ fontWeight: '600', fontSize: '24px' }}>Newly launched games</TextH3> :
          <>
            <TextH3 style={{ fontWeight: '600', fontSize: '20px' }}>Play mobile games on the big screen</TextH3>
            <Text style={{ color: '#5F6368', fontSize: '12px', fontWeight: '400' }}>Google Play Games on PC</Text>
          </>}
      </Box>
      {isLoading ?
        <LoadingBox>
          <ReactLoading type='spinningBubbles' color='black' height={120} width={75} />
        </LoadingBox>
        :
        <StyledSlider>
          {displayedGames && <Slider {...MYSliderSetting}>
            {displayedGames.map((slide, index: number) => {
              return (
                <Box key={index}>
                  { slide && slide.game && slide.game.video && slide.game.video[0] ? 
                    <MainVideo src={slide && slide.game && slide.game.video && slide.game.video[0] ? slide.game.video[0].url : ''} preload="auto" autoPlay muted loop />
                    :
                    <VideoNotFound>
                      Video not Found
                    </VideoNotFound>

                  }
                  <Box style={{ display: "flex", marginTop: "16px" }}>
                    <StyledLogo src={slide.game.icon.url} alt={slide.game.icon.url} onClick={() => navigate(`/viewgame?id=${slide.game.id}`)} />
                    <Box style={{ marginLeft: "16px" }}>
                      <Text style={{ color: 'black', fontWeight: "500" }} onClick={() => navigate(`/viewgame?id=${slide.game.id}`)}>{screenWidth > 600 ? slide.game.name : truncateString(slide.game.name,15)}</Text>
                      <Text style={{ color: '#5F6368', cursor: "pointer" }} onClick={() => navigate('/tongames', { state: { slide } })}><u>{slide.username}</u></Text>
                      <Text style={{ color: '#5F6368', fontSize: "16px" }}>{slide.game.category}</Text>
                      <Text style={{ color: '#5F6368', fontSize: "16px" }}>{slide.game.rating} <StyledIcon className="material-icons">star</StyledIcon></Text>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Slider>}
        </StyledSlider>}
    </Container>
  );
};

export default MainWindowSlider;

