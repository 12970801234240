import React from 'react';
import styled from 'styled-components';
import { Container } from '../lib';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <Container>
            <StyledNavbarWrapper>
                <Link to={'/'} style={{ display: "flex", alignItems: 'center',gap: '16px',textDecoration:"none"}}>
                    <StyledSvgWrapper>
                        <img src='https://firebasestorage.googleapis.com/v0/b/colorapps-87bc6.appspot.com/o/senet%3Asui.png?alt=media&token=aa0d4c52-fbc2-411d-8d71-14a0cf148464' alt='' style={{ height: "50px", width: "50px" }} />
                    </StyledSvgWrapper>
                    <StyledTextWrapper>Earn Senet</StyledTextWrapper>
                </Link>
            </StyledNavbarWrapper>
        </Container>
    );
};

const StyledNavbarWrapper = styled.nav`
    display: flex;
    align-items: center;
    gap: 16px;
    padding:8px 0;
    
`;

const StyledSvgWrapper = styled.div`
    width: 50px;
    margin-top: 6px;
`;

const StyledTextWrapper = styled.h1`
    margin: 0;
    color: #5f6368;
    font-weight: 600;
`;

export default Navbar;
